import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from "react";
import Spinner from "../../../../components/spinner";
import axios from "axios";
import { getAPIConfig } from "../../../../common/utils";
import ENDPOINTS from "../../../../common/endpoints";
import { Modal, Button } from "react-bootstrap";
import PopoverWithHelpIcon from "./PopoverWithHelpIcon";
import BusinessSection from "./Sections/BusinessSection";
import AdaccountsSection from "./Sections/AdaccountsSection";
import PagesSection from "./Sections/PagesSection";
import {
  toggleSelection,
  toggleSelectAll,
  formatSelectionForSubmit,
  formatSelectionForUpdate,
  initializeFormData,
} from "../utils/MetaPopup_Helper";
import { toast } from "react-toastify";

const MetaPopup = ({ accountsData, ConnectAPI, setShowMetaPopup }) => {
  const [data, setData] = useState({});
  const [accessToken, setAccessToken] = useState("");
  const [showModal, setShowModal] = useState(true);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [showRepluginWarning, setShowRepluginWarning] = useState(false);
  const [loading, setLoading] = useState({
    gettingData: false,
    submittingData: false,
  });
  const user = JSON.parse(localStorage.getItem("user"));
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState({
    selectedBusinesses: [],
    selectedAdAccounts: [],
    selectedPages: [],
    selectedPrimaryPage: [],
    selectedInstagramAccounts: [],
    selectedStandaloneAdAccounts: [],
    selectedStandalonePages: [],
  });
  const initialFormDataRef = useRef(null);
  const [loadingMessage, setLoadingMessage] = useState(""); // For dynamic loading message
  const [showUpdate, setShowUpdate] = useState(false);
  const apiHeaderConfig = useMemo(() => getAPIConfig(), []);

  const getTokenData = () => {
    setLoading((prev) => ({ ...prev, gettingData: true }));
    const body = {
      access_token: accessToken,
    };
    axios
      .post(ENDPOINTS.API_FEEDS.getMetaTokenData, body, apiHeaderConfig)
      .then((response) => {
        setData(response.data);
        setStep(1);
      })
      .catch((error) => {
        // console.error("Error generating authentication URI:", error);
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, gettingData: false }));
      });
  };

  const handleSubmit = () => {
    const formattedData = formatSelectionForSubmit(formData, data);
    setLoading((prev) => ({ ...prev, submittingData: true }));
    const body = formattedData;
    axios
      .post(ENDPOINTS.API_FEEDS.storeAccounts, body, apiHeaderConfig)
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch((error) => {
        // Handle error responses
        if (error.response && error.response.data) {
          toast.error(error.response.data.message || "An error occurred."); // Show error message
        } else {
          toast.error("An unexpected error occurred."); // Handle cases without a proper error response
        }
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, submittingData: false }));
        setShowModal(false);
      });
  };

  const handleUpdate = () => {
    const formattedData = formatSelectionForUpdate(
      formData,
      initialFormDataRef.current,
      data
    );
    setLoading((prev) => ({ ...prev, submittingData: true }));
    const body = formattedData;
    axios
      .post(ENDPOINTS.API_FEEDS.updateAccounts, body, apiHeaderConfig)
      .then((response) => {
        toast.success(response.data.message);
      })
      .catch((error) => {
        // Handle error responses
        if (error.response && error.response.data) {
          toast.error(error.response.data.message || "An error occurred."); // Show error message
        } else {
          toast.error("An unexpected error occurred."); // Handle cases without a proper error response
        }
      })
      .finally(() => {
        setLoading((prev) => ({ ...prev, submittingData: false }));
        setShowModal(false);
      });
  };

  useEffect(() => {
    if (accountsData && Object.keys(accountsData).length > 0) {
      setData(accountsData); // Set the data with accountsData
      setShowUpdate(true);
      setStep(1); // Skip to step 1

      const updatedFormData = initializeFormData(accountsData);
      setFormData(updatedFormData);
      initialFormDataRef.current = updatedFormData;
    } else if (user?.client_id !== 105 && !showUpdate) {
      getTokenData();
    }
    // eslint-disable-next-line
  }, [accountsData]);

  useEffect(() => {
    if (loading.gettingData) {
      // Show the first message for 7 seconds
      setLoadingMessage(
        "Fetching your business, ad accounts, and pages... Please wait!"
      );
      const timer = setTimeout(() => {
        setLoadingMessage(
          "Looks like there's a lot of data to process. Sit back and relax while we fetch it for you!"
        );
      }, 7000);

      return () => clearTimeout(timer); // Cleanup the timer on unmount or when loading ends
    }
  }, [loading.gettingData]);

  const handleNext = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleCloseModal = useCallback(() => {
    setShowCloseConfirmation(true);
  }, []);

  const confirmCloseModal = () => {
    setShowCloseConfirmation(false);
    setShowModal(false);
    setShowMetaPopup(false);
  };

  const cancelCloseModal = () => {
    setShowCloseConfirmation(false);
    setShowRepluginWarning(false);
  };

  const infoData = [
    {
      title: "Businesses",
      tooltip:
        "Ad Accounts, Pages, Instagram Accounts associated with Businesses.",
    },
    {
      title: "Individual Ad Accounts",
      tooltip: "Ad Accounts not associated with any business accounts.",
    },
    {
      title: "Individual Pages",
      tooltip: "Pages not associated with any business accounts.",
    },
  ];

  return (
    <Modal show={showModal} onHide={handleCloseModal} size="lg">
      <Modal.Header closeButton>
        <Modal.Title className="d-flex justify-content-between align-items-center w-100">
          <div className="d-flex align-items-center">
            <span>
              {infoData.map(
                (item, index) =>
                  step === index + 1 && (
                    <span key={item.title}>
                      {" "}
                      {item.title}
                      <i
                        className="bi bi-info-circle ms-2 text-muted"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={item.tooltip}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </span>
                  )
              )}
            </span>
          </div>
          {showUpdate && (
            <div className="d-flex align-items-center me-3">
              <PopoverWithHelpIcon
                setShowRepluginWarning={setShowRepluginWarning}
              />{" "}
            </div>
          )}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "400px" }} className="overflow-auto">
        {loading.gettingData || loading.submittingData ? (
          <>
            <Spinner />
            {loading.gettingData && (
              <div className="d-flex justify-content-center mb-2">
                {loadingMessage}
              </div>
            )}
          </>
        ) : showCloseConfirmation ? (
          <div className="text-center">
            <h5>Are you sure you want to exit ?</h5>
          </div>
        ) : showRepluginWarning ? (
          <>
            <div className="text-center">
              <h5>
                This will reset your current Configuration <br />
                Do you want to continue ?
              </h5>
            </div>
          </>
        ) : (
          <>
            {step === 0 && (
              <div className="access-token">
                <div className="d-flex justify-content-between align-items-center py-2">
                  <h6>Access Token</h6>
                </div>
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter your access token"
                    value={accessToken}
                    onChange={(event) => setAccessToken(event.target.value)}
                  />
                </div>
              </div>
            )}
            {step === 1 && (
              <BusinessSection
                data={data}
                formData={formData}
                setFormData={setFormData}
                toggleSelectAll={toggleSelectAll}
              />
            )}
            {step === 2 && (
              <AdaccountsSection
                data={data}
                formData={formData}
                setFormData={setFormData}
                toggleSelection={toggleSelection}
                toggleSelectAll={toggleSelectAll}
              />
            )}
            {step === 3 && (
              <PagesSection
                data={data}
                formData={formData}
                setFormData={setFormData}
                toggleSelection={toggleSelection}
                toggleSelectAll={toggleSelectAll}
              />
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        {showCloseConfirmation || showRepluginWarning ? (
          <>
            <Button
              variant="secondary"
              onClick={cancelCloseModal}
              className="mx-2"
            >
              No
            </Button>
            <Button
              variant={showRepluginWarning ? "primary" : "danger"}
              onClick={
                showRepluginWarning
                  ? () => ConnectAPI("Meta")
                  : confirmCloseModal
              }
              className="mx-2"
            >
              {showRepluginWarning ? "Yes" : "Yes, Exit"}
            </Button>
          </>
        ) : (
          <>
            {step === 0 && (
              <Button
                variant="primary"
                onClick={getTokenData}
                disabled={!accessToken.trim() || loading.gettingData}
              >
                Submit
              </Button>
            )}
            {step > 1 && (
              <Button
                variant="secondary"
                onClick={handleBack}
                disabled={loading.submittingData}
              >
                Back
              </Button>
            )}
            {step > 0 && step < 3 && (
              <Button variant="primary" onClick={handleNext}>
                Next
              </Button>
            )}
            {step === 3 &&
              (showUpdate ? (
                <Button
                  variant="primary"
                  onClick={handleUpdate}
                  disabled={loading.submittingData}
                >
                  Update
                </Button>
              ) : (
                <Button
                  variant="primary"
                  onClick={handleSubmit}
                  disabled={loading.submittingData}
                >
                  Submit
                </Button>
              ))}
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default MetaPopup;
