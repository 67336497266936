import React, { useState, useEffect, useRef } from "react";
import Spinner from "../../../../../components/spinner";
import { highlightText } from "../../utils/MetaPopup_Helper";

const PagesSection = ({
  data,
  formData,
  setFormData,
  toggleSelection,
  toggleSelectAll,
}) => {
  const [standalonePagesVisible, setStandalonePagesVisible] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const loaderRef = useRef(null);
  const observer = useRef(null);

  // Combine visible and non-visible pages for search
  const allPages = [...data.standalone_pages, ...(data.all_pages || [])];

  // Filter pages based on the search term
  const filteredPages = allPages.filter(
    (page) =>
      page.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      page.id.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Limit visible pages based on the standalonePagesVisible state
  const visiblePages = filteredPages.slice(0, standalonePagesVisible);

  useEffect(() => {
    const loadMorePages = (entries) => {
      if (entries[0].isIntersecting) {
        // Add a delay before loading more pages
        setTimeout(() => {
          setStandalonePagesVisible((prev) => prev + 5);
        }, 1000);
      }
    };

    observer.current = new IntersectionObserver(loadMorePages, {
      rootMargin: "100px",
    });

    if (loaderRef.current) {
      observer.current.observe(loaderRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return (
    <div className="standalone-pages">
      {visiblePages.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center">
          No Pages to display
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <input
              type="text"
              className="form-control w-75"
              placeholder="Search by name or ID"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <button
              className="btn btn-link text-decoration-none"
              onClick={() =>
                toggleSelectAll(
                  "selectedStandalonePages",
                  data.standalone_pages,
                  setFormData
                )
              }
            >
              {formData.selectedStandalonePages.length ===
              data.standalone_pages.length
                ? "Deselect All"
                : "Select All"}
            </button>
          </div>
          <div></div>
          {visiblePages.map((page) => (
            <div
              key={page.id}
              className="d-flex justify-content-between align-items-center form-check py-2"
            >
              <label
                htmlFor={`standalonePage-${page.id}`}
                className="form-check-label"
              >
                <div>
                  <span>{highlightText(page.name, searchTerm)}</span>
                  <div style={{ fontSize: "0.8rem", color: "#6c757d" }}>
                    {highlightText(page.id, searchTerm)}
                  </div>
                </div>
              </label>
              <div className="form-check form-switch ms-auto me-3">
                <input
                  type="checkbox"
                  id={`standalonePage-${page.id}`}
                  className="form-check-input cursor-pointer"
                  onChange={() =>
                    toggleSelection(
                      "selectedStandalonePages",
                      page.id,
                      setFormData
                    )
                  }
                  checked={formData.selectedStandalonePages.includes(page.id)}
                  style={{ transform: "scale(1.5)", cursor: "pointer" }}
                />
              </div>
            </div>
          ))}
          {filteredPages.length > standalonePagesVisible && (
            <div ref={loaderRef}>
              <Spinner />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PagesSection;
